import React, { useState } from 'react';

const Message = ({ message, onClose }) => {

  const [showMessage, setShowMessage] = useState(true);


  const closeMessage = () => {
    setShowMessage(false);
    onClose();
};

  return showMessage ? (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white rounded-lg shadow-lg p-8 text-black">
        <h2 className="text-3xl font-semibold mb-6">Message</h2>
        <div className="mb-6">
          <p className="text-black">{message}</p>
        </div>
        <button
          onClick={closeMessage}
          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        >
          Close
        </button>
      </div>
    </div>
  ) : null;
};

export default Message;
