import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useCart } from '../context/CartContext';

const apiLink = 'https://stenzdigital.app/ecommerce/'
const payLink = 'https://stenzdigital.app/pay/checkout'

const ProductPreview = ({
  title,
  buttonLeftTitle,
  buttonRightTitle,
  titleFontSize,
  priceColor,
  backgroundColor,
  textColor,
  productFontSize,
  productTextColor,
  descriptionFontSize,
  priceFontSize,
  buttonBgColor,
  buttonTextColor,
  sizeBgColor,
  sizeTextColor,
}) => {
  const { addToCart } = useCart();
  const location = useLocation();
  const [productDetails, setProductDetails] = useState({
    name: 'Product',
    price: '0.00',
    currency: '€',
    image: 'https://stenzdigital.com/img/stenzdigitallogo.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ipsum nunc, pharetra ac viverra ut, sollicitudin ornare turpis. Vivamus sed nunc a eros dignissim rutrum. Suspendisse quis varius dolor, nec vehicula libero. Morbi vel pulvinar diam, non maximus nisl. Vivamus non ante et enim interdum varius.',
    sizes: ['XS', 'S', 'M', 'L', 'XL']
  });
  const [showCheck, setShowCheck] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeWarning, setSizeWarning] = useState(false);



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const productName = params.get('p');

    fetchProductDetails(productName);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const fetchProductDetails = async (productName) => {
    const url = document.URL.replace(/^(https?:\/\/[^/]+)\/?$/, '$1');
    const formattedProductName = await productName ? productName.replace(/-/g, ' ') : null;
    try {
        const response = await fetch(apiLink + 'getProducts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteUrl: url }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
    
        if (response.ok) {
          const product = data.products.find((product) => product.name === formattedProductName);
          const currency = data.currency;
    
          if (product) {
            setProductDetails({
              id: product.productId,
              name: product.name,
              price: product.price,
              currency: currency,
              image: product.image,
              description: product.description,
              sizes: product.sizes,
            });
            setLoading(false);
          } else {
            console.error(`Product "${formattedProductName}" not found`);
          }    
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    const handleAddToCart = () => {
      if (!selectedSize && productDetails.sizes) {
        setSizeWarning(true);
        setTimeout(() => setSizeWarning(false), 2000);
        return;
      }
        setShowCheck(true);
        addToCart({ ...productDetails, size: selectedSize });
        setTimeout(() => {
            setShowCheck(false);
        }, 2000);
    };

    const handleSizeClick = (size) => {
      setSelectedSize(size);
      setSizeWarning(false);
    };

    const handleInitiateCheckout = async () => {
      const url = document.URL.replace(/^(https?:\/\/[^/]+)\/?$/, '$1');
      try {
        const response = await fetch(payLink, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ domain: url, cartItems: [{ ...productDetails, size: selectedSize }] }), // Ensure cartItems is an array
        });
    
        if (!response.ok) {
          throw new Error('Failed to initiate checkout');
        }
    
        const data = await response.json();
    
        if (data.error) {
          console.log(data.error);
          return;
        }
    
        if (response.ok) {
          window.location.replace(data.url);
        }
      } catch (error) {
        console.error('Error initiating checkout:', error);
      }
    };

  const renderSkeleton = () => (
    <div className={`flex flex-col sm:flex-row justify-between items-start m-auto max-w-screen-xl p-8 sm:p-20 shadow-lg ${backgroundColor}`}>
      <div className="w-64 h-64 bg-gray-300 rounded-lg mr-8 mb-8 sm:mb-0"></div>
      <div className="flex-grow">
        <div className="w-full h-8 bg-gray-300 mb-4"></div>
        <div className="w-full h-6 bg-gray-300 mb-4"></div>
        <div className="w-full h-4 bg-gray-300 mb-4"></div>
        <div className="flex mb-4">
          <div className="w-16 h-8 bg-gray-300 rounded-full mr-2"></div>
          <div className="w-16 h-8 bg-gray-300 rounded-full mr-2"></div>
          <div className="w-16 h-8 bg-gray-300 rounded-full mr-2"></div>
        </div>
        <div className="flex">
          <div className="w-32 h-10 bg-gray-300 rounded-full mr-2"></div>
          <div className="w-32 h-10 bg-gray-300 rounded-full"></div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={`${textColor} ${titleFontSize} text-center p-2 py-4`}>
        <h2>{title}</h2>
      </div>
      {isLoading ? (
        renderSkeleton()
      ) : (
      <div className={`flex flex-col sm:flex-row justify-between items-start m-auto max-w-screen-xl p-8 sm:p-20 shadow-lg ${backgroundColor}`}>
        <img src={productDetails.image} alt={productDetails.name} className="mx-auto sm:mx-0 sm:mr-12 mb-8 sm:mb-0 max-w-full sm:max-w-none transition-transform duration-300 ease-in-out transform hover:scale-105" style={{ maxWidth: '300px' }} />
        <div className="mx-auto sm:ml-8 flex-grow">
          <h3 className={`${productFontSize} mb-4 ${productTextColor}`}>{productDetails.name}</h3>
          <p className={`${descriptionFontSize} mb-4`}>{productDetails.description}</p>
          <p className={`${priceFontSize} font-bold mb-4 ${priceColor}`}>{productDetails.currency}{productDetails.price}</p>
          <ul className={`flex flex-wrap mb-4 ${sizeWarning ? 'border-4 p-4 border-red-500 animate-pulse' : ''}`}>
            {productDetails.sizes && productDetails.sizes.map((size) => (
              <li key={size}>
                <button
                  className={`${sizeBgColor} ${sizeTextColor} py-2 px-4 rounded-full font-bold uppercase mb-2 mr-2 sm:mr-4 transition-transform duration-300 ease-in-out transform hover:scale-105 ${selectedSize === size ? 'border-2 border-blue-500' : ''}`}
                  onClick={() => handleSizeClick(size)}
                >
                  {size}
                </button>
              </li>
            ))}
          </ul>
          <div className="flex flex-col sm:flex-row text-center">
            <button className={`py-2 px-4 rounded-full font-bold uppercase ${buttonBgColor} ${buttonTextColor} mb-2 sm:mb-0 sm:mr-2 transition-transform duration-300 ease-in-out transform hover:scale-105`} onClick={handleInitiateCheckout}>
              {buttonLeftTitle}
            </button>
            <button className={`py-2 px-4 rounded-full font-bold uppercase ${buttonBgColor} ${buttonTextColor} transition-transform duration-300 ease-in-out transform hover:scale-105`} onClick={handleAddToCart}>
              {showCheck ? (
                <div className="flex items-center justify-center">
                  <FaCheckCircle className={`h-6 w-6 ${buttonTextColor} mx-2 animate-fade-in-out`} />
                </div>
              ) : (
                buttonRightTitle
              )}
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ProductPreview;
