import React, { useEffect, useState } from 'react';
import { useCart } from '../context/CartContext';

const payLink = 'https://stenzdigital.app/pay/checkout'

const Cart = ({ 
  title, 
  backgroundColor, 
  textColor, 
  titleFontSize, 
  productFontSize, 
  priceColor, 
  priceFontSize, 
  buttonBgColor, 
  buttonTextColor, 
  buttonTitle 
}) => {
  const { cartItems, removeFromCart } = useCart();
  const [currency, setCurrency] = useState('');


  const subtotal = cartItems.reduce((acc, item) => acc + parseFloat(item.price), 0);

  const handleRemove = (index) => {
    removeFromCart(index);
  };

    // Function to modify cart items to show unique products with quantities
  const modifyCartItems = (cartItems) => {
    const uniqueItems = {};

    cartItems.forEach(item => {
      const key = item.size ? `${item.name} - ${item.size}` : item.name;
      if (!uniqueItems[key]) {
        uniqueItems[key] = { ...item, quantity: 1 };
      } else {
        uniqueItems[key].quantity++;
      }
    });

    return Object.values(uniqueItems);
  };

  const modifiedCartItems = modifyCartItems(cartItems);

  useEffect(() => {
    if(cartItems) {
      cartItems.forEach(item => {
        setCurrency(item.currency);
      });
    }
  }, [cartItems]);
  

  const handleCheckout = async () => {
    const url = document.URL.replace(/^(https?:\/\/[^/]+)\/?$/, '$1');
    try {
      const response = await fetch(payLink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain: url, cartItems: modifiedCartItems }), // Ensure cartItems is an array
      });
  
      const data = await response.json();
  
      if (data.error) {
        console.log(data.error);
        return;
      }
  
      if (response.ok) {
        window.location.replace(data.url);
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
    }
  };
  

  return (
    <div className={`p-4 ${backgroundColor}`}>
      {title && <h2 className={`text-center ${titleFontSize} ${textColor} mb-4`}>{title}</h2>}
      <div className="grid grid-cols-1 gap-4">
      {modifiedCartItems.map((item, index) => (
          <div key={index} className={`p-2 border rounded ${textColor} flex justify-between items-center`}>
            <div>
              <h3 className={`mb-1 ${productFontSize}`}>
                {item.name} {item.size && `- ${item.size}`} {item.quantity > 1 && `x${item.quantity}`}
              </h3>
              <p className={`mb-1 ${priceFontSize} ${priceColor}`}>{item.currency}{item.price}</p>
            </div>
            <button onClick={() => handleRemove(index)} className="text-red-500">Remove</button>
          </div>
        ))}
      </div>
      <div className={`mt-4 p-4 border-t ${textColor}`}>
        <div className="flex justify-between items-center">
          <h3 className={`text-lg ${textColor}`}>Subtotal:</h3>
          <p className={`${priceColor}`}>{currency}{subtotal.toFixed(2)}</p>
        </div>
        <button onClick={handleCheckout} className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full shadow transition duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1`}>
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default Cart;
