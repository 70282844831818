import React from 'react';
import ProductSection from './components/conf/ProductSection';
import Cart from './components/conf/Cart';
import Header from './components/conf/Header';
import ProductPreview from './components/conf/ProductPreview';

const config = {
  components: {
    HeroSection: {
      fields: {
        title: {
          label: "Title",
          type: "text",
        },
        subtitle: {
          label: "Subtitle",
          type: "text"
        },
        textColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Text Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },      
        enableButton: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Enable Button</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
              </select>
            </div>
          ),
        },
        buttonLink: {
          label: "Button Link",
          type: "text"
        },
        buttonText: {
          label: "Button Text",
          type: "text"
        },
        buttonTextColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Text Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonBackgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
               <option value="bg-blue-500">Blue</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-orange-300">Orange</option>
              </select>
            </div>
          ),
        },
        buttonHoverColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Hover Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-blue-600">Blue</option>
                <option value="bg-red-600">Red</option>
                <option value="bg-orange-400">Orange</option>
              </select>
            </div>
          ),
        },       
      },
      defaultProps: {
        title: "Lorem ipsum dolor sit amet",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        enableButton: "true",
        buttonText: "Button",
        buttonLink: "https://stnz.app/",
        buttonTextColor: "text-white",
        textColor: "text-black",
        backgroundColor: "bg-white",
        buttonBackgroundColor: "bg-blue-500",
        buttonHoverColor: "bg-blue-600",
      },
      render: ({ title, subtitle, enableButton, buttonText, buttonLink, buttonTextColor, textColor, backgroundColor, buttonBackgroundColor, buttonHoverColor }) => {
        const handleButtonClick = async () => {
          window.location.href = buttonLink;
        }

        return (
          <section className={`${backgroundColor} ${textColor} p-4 py-16`}>
            <div className="container mx-auto text-center">
              <h1 className="text-4xl font-bold mb-4">{title}</h1>
              <p className="text-lg mb-8">{subtitle}</p>
              {enableButton === "true" && (
                <>
                  <button onClick={handleButtonClick} className={`${buttonBackgroundColor} hover:${buttonHoverColor} ${buttonTextColor} font-bold py-2 px-4 rounded shadow transition duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1`}>
                    {buttonText}
                  </button>
                </>
              )}
            </div>
          </section>
        );
      },
    },
    ImageSection: {
      fields: {
        imageUrl: {
          label: "Image Url",
          type: "text",
        },
        altText: {
          label: "Alt Text",
          type: "text"
        },
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },      
      },
      defaultProps: {
        imageUrl: "https://stenzdigital.com/img/stenzdigitallogo.png",
        altText: "Placeholder Image",
        backgroundColor: "bg-white",
      },
      render: ({ imageUrl, altText, backgroundColor }) => {
        return (
          <section className={`py-12 ${backgroundColor}`}>
            <div className="container mx-auto">
              <img src={imageUrl} alt={altText} className="mx-auto" />
            </div>
          </section>
        );
      },
    },
    videoSection: {
      fields: {
        url: {
          label: "Url",
          type: "text",
        },
        label: {
          type: "custom",
          render: () => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-xs font-medium mb-2">*Only embeds from YouTube & Google Drive allowed</label>
              <label htmlFor="color" className="block text-xs font-medium mb-2 mt-5">File uploads coming soon</label>
            </div>
          ),
        },      
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },      
      },
      defaultProps: {
        url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        backgroundColor: "bg-white",        
      },
      render: ({ url, backgroundColor }) => {
        if(!url.includes('youtube.com') && !url.includes('/embed'))
        {
          if(!url.includes('drive.google.com'))
          {
            return;
          }
        }
        return (
          <section className={`py-12 ${backgroundColor}`}>
            <div className="container mx-auto">
              <div className="aspect-w-16 aspect-h-9">
                {/* eslint-disable-next-line */}
                <iframe
                  src={url}
                  height={"640"}
                  className="w-full"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </section>
        );
      },
    },
    TextSection: {
      fields: {
        content: {
          type: "textarea",
          label: "Content",
        },
        textColor: {
          type: "custom",
          label: "Text Color",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">Text Color</label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        textAlignment: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Text Alignment</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-center">Center</option>
                <option value="text-left">Left</option>
                <option value="text-right">Right</option>
              </select>
            </div>
          ),
        },
        textSize: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Text Size</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-base">Medium</option>
                <option value="text-2xl">Extra Extra Large</option>
                <option value="text-xl">Extra Large</option>
                <option value="text-lg">Large</option>
                <option value="text-sm">Small</option>
                <option value="text-xs">Extra Small</option>
              </select>
            </div>
          ),
        },             
      },
      defaultProps: {
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        textColor: "text-black",
        textAlignment: "text-center",
        backgroundColor: "bg-white",
      },
      render: ({ content, textColor, textAlignment, backgroundColor, textSize}) => {
        return (
          <section className={`py-12 p-4 ${textColor} ${textAlignment} ${backgroundColor}`}>
            <div className="container mx-auto">
              <p className={`${textSize}`}>{content}</p>
            </div>
          </section>
        );
      },
    },
    FooterSection: {
      fields: {
        content: {
          type: "textarea",
          label: "Content",
        },
        textColor: {
          type: "custom",
          label: "Text Color",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">Text Color</label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          label: "Background Color",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
      },
      defaultProps: {
        content: "© 2024 Stenz Digital - All Rights Reserved.",
        textColor: "text-black",
        backgroundColor: "bg-white",
      },
      render: ({ content, textColor, backgroundColor }) => {
        return (
          <>
          <footer className={`${backgroundColor} ${textColor} py-8`}>
            <div className="container mx-auto text-center">
              <p className={`font-semibold`}>{content}</p>
            </div>
          </footer>
          </>
        );
      },
    },
    button: {
      fields: {
        title: {
          label: "Button Text",
          type: "text",
        },
        buttonLink: {
          label: "Button Link",
          type: "text"
        },
        buttonTextColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Text Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonBackgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
               <option value="bg-blue-500">Blue</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-orange-300">Orange</option>
              </select>
            </div>
          ),
        },
        buttonHoverColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Hover Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-blue-600">Blue</option>
                <option value="bg-red-600">Red</option>
                <option value="bg-orange-400">Orange</option>
              </select>
            </div>
          ),
        },
        buttonAlignment: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Alignment</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-center">Center</option>
                <option value="text-left">Left</option>
                <option value="text-right">Right</option>
              </select>
            </div>
          ),
        },       
      },
      defaultProps: {
        title: "Button",
        buttonLink: "https://stnz.app/",
        buttonTextColor: "text-white",
        buttonBackgroundColor: "bg-blue-500",
        buttonHoverColor: "bg-blue-600",
        buttonAlignment: "text-center",
      },
      render: ({ title, buttonLink, buttonTextColor, buttonBackgroundColor, buttonHoverColor,  buttonAlignment }) => {
        const handleButtonClick = async () => {
          window.location.href = buttonLink;
        }

        return (
            <div className={`container mx-auto p-2 ${buttonAlignment}`}>
              <button onClick={handleButtonClick} className={`${buttonBackgroundColor} hover:${buttonHoverColor} ${buttonTextColor} font-bold py-2 px-4 rounded`}>
                {title}
              </button>
            </div>
        );
      },
    },
    ProductSection: {
      fields: {
        title: {
          label: "Section Title",
          type: "text",
        },
        priceColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceColor" className="block text-sm font-medium mb-2">
                Price Color
              </label>
              <select
                id="priceColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-gray-500">Gray</option>
                <option value="text-gray-100">Light Gray</option>
                <option value="text-gray-800">Dark Gray</option>
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-green-500">Green</option>
                <option value="text-red-500">Red</option>
                <option value="text-blue-500">Blue</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        textColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        titleFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="titleFontSize" className="block text-sm font-medium mb-2">
                Title Font Size
              </label>
              <select
                id="titleFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        productFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productFontSize" className="block text-sm font-medium mb-2">
                Product Font Size
              </label>
              <select
                id="productFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        buttonBgColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonBgColor" className="block text-sm font-medium mb-2">
                Button Background Color
              </label>
              <select
                id="buttonBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
        buttonTextColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonTextColor" className="block text-sm font-medium mb-2">
                Button Text Color
              </label>
              <select
                id="buttonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonTitle: {
          label: "Button Title",
          type: "text",
        },
      },
      defaultProps: {
        title: "Products",
        priceColor: "text-gray-500",
        backgroundColor: "bg-white",
        textColor: "text-black",
        titleFontSize: "text-xl",
        productFontSize: "text-md",
        buttonBgColor: "bg-yellow-500",
        buttonTextColor: "text-white",
        buttonTitle: "Buy Now",
      },
      render: (props) => {
        return <ProductSection {...props} />;
      },
    },
    Cart: {
      fields: {
        title: {
          label: "Section Title",
          type: "text",
        },
        priceColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceColor" className="block text-sm font-medium mb-2">
                Price Color
              </label>
              <select
                id="priceColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-gray-500">Gray</option>
                <option value="text-gray-100">Light Gray</option>
                <option value="text-gray-800">Dark Gray</option>
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-green-500">Green</option>
                <option value="text-red-500">Red</option>
                <option value="text-blue-500">Blue</option>
              </select>
            </div>
          ),
        },
        priceFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceFontSize" className="block text-sm font-medium mb-2">
                Price Font Size
              </label>
              <select
                id="priceFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        textColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        titleFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="titleFontSize" className="block text-sm font-medium mb-2">
                Title Font Size
              </label>
              <select
                id="titleFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        productFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productFontSize" className="block text-sm font-medium mb-2">
                Product Font Size
              </label>
              <select
                id="productFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        buttonBgColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonBgColor" className="block text-sm font-medium mb-2">
                Button Background Color
              </label>
              <select
                id="buttonBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
        buttonTextColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonTextColor" className="block text-sm font-medium mb-2">
                Button Text Color
              </label>
              <select
                id="buttonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonTitle: {
          label: "Button Title",
          type: "text",
        },
      },
      defaultProps: {
        title: "Cart",
        priceColor: "text-gray-500",
        priceFontSize: "text-md",
        backgroundColor: "bg-white",
        textColor: "text-black",
        titleFontSize: "text-xl",
        productFontSize: "text-md",
        buttonBgColor: "bg-yellow-500",
        buttonTextColor: "text-white",
        buttonTitle: "Checkout",
      },
      render: (props) => {
        return <Cart {...props} />;
      },
    },
    Header: {
      fields: {
        title: {
          label: "Header Title",
          type: "text",
        },
        links: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Header Links</label>
              {value.map((link, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    className="rounded-l-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="URL"
                    value={link.url}
                    onChange={(e) => {
                      const newValue = [...value];
                      newValue[index].url = e.target.value;
                      onChange(newValue);
                    }}
                  />
                  <input
                    type="text"
                    className="rounded-r-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Text"
                    value={link.text}
                    onChange={(e) => {
                      const newValue = [...value];
                      newValue[index].text = e.target.value;
                      onChange(newValue);
                    }}
                  />
                  <button
                    type="button"
                    className="ml-2 py-2 px-4 bg-red-500 text-white rounded-lg"
                    onClick={() => {
                      const newValue = [...value];
                      newValue.splice(index, 1);
                      onChange(newValue);
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="py-2 px-4 bg-green-500 text-white rounded-lg"
                onClick={() => {
                  onChange([...value, { url: "", text: "" }]);
                }}
              >
                Add Link
              </button>
            </div>
          ),
        },
        logo: {
          label: "Logo URL",
          type: "text",
        },
        textColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        fontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="fontSize" className="block text-sm font-medium mb-2">
                Font Size
              </label>
              <select
                id="fontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        fontStyle: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="fontStyle" className="block text-sm font-medium mb-2">
                Font Style
              </label>
              <select
                id="fontStyle"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="font-bold">Bold</option>
                <option value="italic">Italic</option>
                <option value="">Normal</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        cartButtonText: {
          label: "Cart Button Text",
          type: "text",
        },
        cartButtonFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonFontSize" className="block text-sm font-medium mb-2">
                Cart Button Font Size
              </label>
              <select
                id="cartButtonFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        cartButtonFontStyle: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonFontStyle" className="block text-sm font-medium mb-2">
                Cart Button Font Style
              </label>
              <select
                id="cartButtonFontStyle"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="font-bold">Bold</option>
                <option value="italic">Italic</option>
                <option value="">Normal</option>
              </select>
            </div>
          ),
        },
        cartButtonTextColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonTextColor" className="block text-sm font-medium mb-2">
                Cart Button Text Color
              </label>
              <select
                id="cartButtonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        cartButtonBackgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonBackgroundColor" className="block text-sm font-medium mb-2">
                Cart Button Background Color
              </label>
              <select
                id="cartButtonBackgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
      },
      defaultProps: {
        title: "",
        links: [
          { url: "/", text: "Home" },
          { url: "/products", text: "Products" },
          { url: "/contact", text: "Contact" },
          { url: "/about", text: "About" },
        ],
        logo: "https://stenzdigital.com/img/stenzdigitallogo.png",
        textColor: "text-black",
        fontSize: "text-xl",
        fontStyle: "",
        backgroundColor: "bg-white",
        cartButtonText: "Cart",
        cartButtonFontSize: "text-md",
        cartButtonFontStyle: "font-bold",
        cartButtonTextColor: "text-white",
        cartButtonBackgroundColor: "bg-blue-500",
      },
      render: (props) => {
        return <Header {...props} />;
      },
    },
    ProductPreview: {
      fields: {
        title: {
          label: "Title",
          type: "text",
        },
        buttonLeftTitle: {
          label: "Button 1 Title",
          type: "text",
        },
        buttonRightTitle: {
          label: "Button 2 Title",
          type: "text",
        },
        titleFontSize: {
          label: "Title Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="titleFontSize" className="block text-sm font-medium mb-2">
                Title Font Size
              </label>
              <select
                id="titleFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        priceColor: {
          label: "Price Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceColor" className="block text-sm font-medium mb-2">
                Price Color
              </label>
              <select
                id="priceColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-gray-500">Gray</option>
                <option value="text-gray-100">Light Gray</option>
                <option value="text-gray-800">Dark Gray</option>
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-green-500">Green</option>
                <option value="text-red-500">Red</option>
                <option value="text-blue-500">Blue</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          label: "Background Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        textColor: {
          label: "Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        productFontSize: {
          label: "Product Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productFontSize" className="block text-sm font-medium mb-2">
                Product Font Size
              </label>
              <select
                id="productFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        productTextColor: {
          label: "Product Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productTextColor" className="block text-sm font-medium mb-2">
                Product Text Color
              </label>
              <select
                id="productTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        descriptionFontSize: {
          label: "Description Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="descriptionFontSize" className="block text-sm font-medium mb-2">
                Description Font Size
              </label>
              <select
                id="descriptionFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        priceFontSize: {
          label: "Price Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceFontSize" className="block text-sm font-medium mb-2">
                Price Font Size
              </label>
              <select
                id="priceFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        buttonBgColor: {
          label: "Button Background Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonBgColor" className="block text-sm font-medium mb-2">
                Button Background Color
              </label>
              <select
                id="buttonBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
        buttonTextColor: {
          label: "Button Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonTextColor" className="block text-sm font-medium mb-2">
                Button Text Color
              </label>
              <select
                id="buttonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        sizeBgColor: {
          label: "Size Button Background Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="sizeBgColor" className="block text-sm font-medium mb-2">
                Size Button Background Color
              </label>
              <select
                id="sizeBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-gray-200">Gray</option>
                <option value="bg-white">White</option>
                <option value="bg-blue-200">Blue</option>
              </select>
            </div>
          ),
        },
        sizeTextColor: {
          label: "Size Button Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="sizeTextColor" className="block text-sm font-medium mb-2">
                Size Button Text Color
              </label>
              <select
                id="sizeTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-blue-800">Blue</option>
              </select>
            </div>
          ),
        },
      },
      defaultProps: {
        title: "Product Preview",
        buttonLeftTitle: "Buy Now",
        buttonRightTitle: "Add to Cart",
        titleFontSize: "text-xl",
        priceColor: "text-gray-500",
        backgroundColor: "bg-white",
        textColor: "text-black",
        productFontSize: "text-xl",
        productTextColor: "text-gray-900",
        descriptionFontSize: "text-base",
        priceFontSize: "text-lg",
        buttonBgColor: "bg-yellow-500",
        buttonTextColor: "text-white",
        sizeBgColor: "bg-gray-200",
        sizeTextColor: "text-black",
      },
      render: (props) => {
        return <ProductPreview {...props} />;
      },
    },
  },
};

export default config;
