import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Render } from "@measured/puck";
import "@measured/puck/puck.css";
import config from '../config';
import init from '../init';
import './Loader.css';
import Message from './Message';
import { useLocation } from 'react-router-dom';

const Site = () => {
  const [homeSiteInfo, setHomeSiteInfo] = useState(null);
  const [siteInfo, setSiteInfo] = useState(null);
  const [pages, setPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');

  const handleCloseMessage = () => {
    setShowMessage(false);
    window.location.replace('https://stenzdigital.com/');
  };

  const location = useLocation();
  const api = 'https://stenzdigital.app/siteEditor/';

  const getSiteInfo = async () => {
    if (siteInfo) {
      return;
    }
    try {
      const url = document.URL.replace(/^(https?:\/\/[^/]+)\/?$/, '$1');
      const response = await fetch(api + 'loadSite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ siteUrl: url })
      });

      if (!response.ok) {
          setMessage('Failed to load site. Please try again later. (load/failed)');
          setError(true);
          setShowMessage(true);
          return;
      }

      const data = await response.json();
      const structuredData = structureSiteInfo(data.siteInfo);
      const home = [{pageId: 'home', pagePath: '/', siteInfo: structuredData}];
      setPages([...home, ...data.pages]);
      if(location.pathname === '/')
      {
        setSiteInfo(structuredData);
      }
      setHomeSiteInfo(structuredData);
      setLoading(false);
    } catch (error) {
      console.error('Error getting site data:', error);
    }
  };

  useEffect(() => {
    getSiteInfo();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(pages)
    {
      const page = pages.find(page => page.pagePath === location.pathname)
      if(page)
      {
        if(page.pagePath === '/')
        {
          setSiteInfo(homeSiteInfo);
        }
        setSiteInfo(structureSiteInfo(page.siteInfo));
      }
    }
  }, [location.pathname, pages, homeSiteInfo])
  

  const structureSiteInfo = (siteInfo) => {
    if (!siteInfo || !siteInfo.content || !siteInfo.root) {
      return null;
    }
  
    const structuredData = {
      content: siteInfo.content.map(item => ({
        type: item.type,
        props: { ...item.props }
      })),
      root: { ...siteInfo.root }
    };
  
    return structuredData;
  };

  const LoadingScreen = (
    <div className="h-screen flex justify-center items-center bg-white">
      <div className="flex flex-col items-center">
        <img src="https://stenzdigital.com/img/stenzdigitallogo.png" alt="StenzDigital Logo" className="w-40 h-40 mb-4" />
        <div>
          <h1 className='text-lg p-4 font-bold italic'>Loading</h1>
        </div>
        <div className="loader"></div>
      </div>
    </div>
  );

  return (
    <div className="h-screen bg-white">
      <Helmet>
        <title>
          {siteInfo && siteInfo.root && siteInfo.root.props.title ? (
            siteInfo.root.props.title
          ) : (
            init.root.title
          )}
        </title>
      </Helmet>
  
      {!error && loading && (
        LoadingScreen
      )}

      {!loading && (
        <Render config={config} data={siteInfo || init} />
      )}

      {showMessage && (
        <Message
          message={message}
          onClose={handleCloseMessage}
        />
      )}
    </div>
  );
};

export default Site;
