import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom';

const apiLink = 'https://stenzdigital.app/ecommerce/'

const ProductSection = ({ title, priceColor, backgroundColor, textColor, titleFontSize, productFontSize, buttonBgColor, buttonTextColor, buttonTitle }) => {
  const [products, setProducts] = useState([]);
  const [currency, setCurrency] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const url = document.URL.replace(/^(https?:\/\/[^/]+)\/?$/, '$1');
      try {
        const response = await fetch(apiLink + 'getProducts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({siteUrl: url}),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();

        if (response.ok)
        {
          setProducts(data.products);
          setCurrency(data.currency);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleViewProduct = (productName) => {
    const formattedName = productName.replace(/\s+/g, '-');
    navigate(`/preview?p=${formattedName}`);
  };

  return (
    <div className={`p-4 ${backgroundColor}`}>
      {title && <h2 className={`text-center ${titleFontSize} ${textColor} mb-4`}>{title}</h2>}
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {[...Array(3)].map((_, index) => (
            <div key={index} className={`p-4 border rounded ${textColor} animate-pulse`}>
              <div className={`mb-2 ${productFontSize} bg-gray-300 h-6 w-3/4`}></div>
              <div className="w-full h-48 mb-2 bg-gray-300"></div>
              <div className="mb-2 bg-gray-300 h-4 w-full"></div>
              <div className={`${priceColor} bg-gray-300 h-4 w-1/2`}></div>
              <div className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full bg-gray-300`}></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product, index) => (
            <div key={index} className={`p-4 border rounded ${textColor}`}>
              <h3 className={`mb-2 ${productFontSize}`}>{product.name}</h3>
              {product.image && (
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-auto mb-2 cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => handleViewProduct(product.name)}
                />
              )}
              <p className={`${priceColor}`}>{currency}{product.price}</p>
              <button
                className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full transition-transform duration-300 ease-in-out transform hover:scale-105`}
                onClick={() => handleViewProduct(product.name)}
              >
                {buttonTitle}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSection;
